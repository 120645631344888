<template>
    <section @dragover="handleDrag">
        <header>
            <div class="title">
                <span class="text">{{status.name}} ({{ total + newEntries }})</span>
                <span class="color" :style="{background: color}"></span>
            </div>
        </header>
        <div class="data" @scroll="handleScroll">
            <template v-if="entries.length">
                <template v-for="(item, i) in entries" :key="i">
                    <Ticket :allStatus="allStatus" :column="status.id" :users="users" :data="item" @startDrag="handleStartDrag" @stopDrag="handleStopDrag" @updateData="getData" />
                </template>
            </template>
            <template v-else-if="loaded && !entries.lenght && !filter.length">
                <span class="no-tickets">
                    There are currently no tickets for this status.
                </span>
            </template>
            <template v-else-if="loaded && !entries.lenght && filter.length">
                <span class="no-tickets">
                    There are currently no tickets in this status for this filter.
                </span>
            </template>
            <template v-if="!loaded">
                <div class="loading">
                    <span class="loader"></span>
                </div>
            </template>
            
        </div>
    </section>
</template>

<script>
import axiosInstance from '@/services/AxiosTokenInstance'
import Ticket from './Item.vue'


export default {
    props: {
        status: {
            type :Object,
            required: true
        },
        color: {
            type :String,
            required: true
        },
        users: {
			type: Array,
			required: false,
			default: () => []
		},
        newItem: {
            type :Object,
            required: false,
            default: () => {}
        },
        splice: {
            type :Object,
            required: false,
            default: () => {}
        },
        filter: {
			type: Array,
			required: false,
			default: () => []
		},
        allStatus: {
			type: Array,
			required: false,
			default: () => []
		},
    },

    components: {
        Ticket
    },

    data() {
        return {
            for_now: [
                {
                    id: 1,
                    string: 'not_assigned'
                },
                {
                    id: 2,
                    string: 'ticket_allocated'
                },
                {
                    id: 3,
                    string: 'ticket_inprogress'
                },
                {
                    id: 4,
                    string: 'ticket_inreview'
                },
                {
                    id: 5,
                    string: 'ticket_completed'
                }
            ],
            total: 0,
            entries: [],
            newEntries: 0,
            page: 1,
            totalPages: 0,
            loaded: false,
        }
    },

    computed: {
        currentStatus() {
            return this.for_now.filter(el => el.id === this.status.id)[0]
        }
    },

    watch: {
        newItem(val) {
            if(Object.keys(val).length) {
                this.entries.splice(0,0,val)
                this.newEntries += 1
            }
        },

        splice(val) {
            if(Object.keys(val).length) {
                const i = this.entries.map(el=> el.id).indexOf(val.id)
                this.entries.splice(i, 1)
                this.newEntries -= 1
            }
        },

        filter() {
            if (this.filter.length && this.filter[0] === 'day' && this.status.id === 5) this.filterData()
            else if (this.filter.length) this.filterData()
            else if (!this.filter.length) this.getData()
        },

        page() {
            this.getData()
        }
    },

    methods: {
        async getData() {
            this.loaded = false
            const org = JSON.parse(localStorage.getItem("organization"));
            const response = await axiosInstance.get(`new-ticket-overview?org_id=${org.id}&ticketStatus=${this.status.id}&page=${this.page}`).finally(() => this.loaded = true)
            const data = response.data.data[this.currentStatus.string]
            this.totalPages = data.last_page
            this.total = data.total

            if(this.page > 1) this.entries.push(...data.data)
            else this.entries = data.data
        },

        async filterData() {
            this.loaded = false
            const org = JSON.parse(localStorage.getItem("organization"));
            const response = await axiosInstance.get(`new-ticket-overview?org_id=${org.id}&ticketStatus=${this.status.id}&${this.filter[0]}=${this.filter[1]}`).finally(() => this.loaded = true)
            const data = response.data.data[this.currentStatus.string]
            this.total = data.total
            this.entries = data.data
        },

        handleStartDrag(data) {
            this.$emit('startDrag', [data, this.status.id])
        },

        handleDrag(e) {
            e.preventDefault();
            this.$emit('handleDrag', this.status.id)
        },

        handleStopDrag() {
            this.$emit('stopDrag')
        },

        handleScroll(e) {
            const maxScroll = e.target.scrollHeight - e.target.offsetHeight;
            const scroll = e.target.scrollTop;

            if (scroll === maxScroll && this.page < this.totalPages) {
                this.page += 1
            }
        }
    },

    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped>
section {
    width: 100%;
    height: 100%;
    background: white;
    border-radius: 2px;
    overflow: hidden;

    @media only screen and (max-width: 768px) {
        width: 100%;
        overflow: unset;
    }

    header {
        height: 12%;
        padding: 20px;
        box-shadow: 0 1px 6px rgba(0,0,0,0.1);
        display: flex;
        align-items: center;

        @media only screen and (max-width: 768px) {
            height: 5rem;
        }

        .title {
            display: flex;
            flex-direction: column;
            gap: 0.2rem;
            
            .text {
                font-weight: 600;
            }

            .color {
                width: 1.5rem;
                height: 5px;
                border-radius: 30px;
            }
        }
    }

    .data {
        height: 88%;
        overflow: auto;
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        position: relative;

        .no-tickets {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px;
            text-align: center;
        }

        .loading {
            position: absolute;
            background: rgba(0,0,0,0.01);
            width: 100%;
            height: 100%;
        }
    }

    
}

</style>