<template>
  <div class="contentTitle">
    <h3>Tickets Overview</h3>
  </div>
  <div class="contentMenu">
    <ul class="navbar-nav">
      <li class="nav-item active">
        <a class="nav-link" href="#">All Tickets ({{ total }})</a>
      </li>
    </ul>
    <div class="search-filters">
      <div class="nav-item">
          <filter-by :items="toFilter" @selectedItem="filterByItem" :left="true"/>
      </div>
    </div>
  </div>
  <columns :users="users" :filter="filter" />
</template>

<script>
import Columns from "./Columns.vue";
import timeSince from "@/mixins/timeSince"
import FilterBy from '@/components/Ui/General/FilterBy.vue'
import api from "@/mixins/optionsApi";

export default {
  title() {
    return `Remote Assistance | Overview`;
  },
  data() {
    return {
        create: false,
        total: 0,
        imgURL: process.env.VUE_APP_DO_SPACES,
        client: localStorage.getItem("client")
            ? JSON.parse(localStorage.getItem("client"))
            : null,
        filter: [],
        users: [],
        closeForm: false,
        openedSidebar: false
    };
  },

  components: {
    Columns,
    FilterBy
  },

  mixins: [timeSince, api],

  computed: {
    toFilter() {
      return [
        {
          name: "completed date",
          options: [
            {
              id: 'today',
              name: 'today'
            },
            {
              id: 'currentWeek',
              name: 'last week'
            },
            {
              id: 'currentMonth',
              name: 'last month'
            }
          ]
        },
        {
          name: "product",
          options: this.api('products')
        },
        {
          name: "category",
          options: this.api('categories')
        }
      ];
    },
  },

  methods: {
    filterByItem(data) {
      switch (data[0]) {
        case "completed date":
          this.filter = ["day", data[1]];
          break;
        case "product":
          this.filter = ["productId", data[1]];
          break;
        case "category":
          this.filter = ["categoryId", data[1]];
          break;
        case "remove":
          this.filter = [];
        break;
      }
    },
    async getUsers() {
      const response = await this.api('org_users')
      this.users = response
    }
  },

  mounted() {
    this.getUsers()
  }
};
</script>

<style lang="scss" scoped>
.contentMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.2s;
  width: 100%;
    .search-filters {
        display: flex;
        gap: 20px;
        align-items: center;
        .searchPan {
        position: relative;
        display: flex;
        align-items: center;
        .searchIcon {
            position: absolute;
            left: 10px;
            color: #cad6e2;
        }
        .form-control {
            padding: 0 30px;
            &::placeholder {
            color: #cad6e2;
            }
        }
        }
    }
}

.loading {
  height: 500px;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid var(--primary-color);
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>