<template>
    <div class="delete-ticket">
        <div class="popup">
            <div class="title">delete ticket</div>
            <div class="message">
                <span class="warning">
                    Are you sure you want to delete?
                </span>
                <div class="ticket">
                    <div class="color" :class="[ticket.ticket_priority.priority_details.name.toLowerCase()]"></div>
                    <div class="info">
                        <div class="name">{{ticket.title}}</div>
                        <div class="serial">{{ticket.product.name}} #{{ticket.product.serial_no}}</div>
                        <div class="client">unknown</div>
                        <div class="priority">
                            <div class="current" @click="openPriorities = ticket.id">
                                <div class="prio">
                                    <img :src="ticket.ticket_priority.priority_details.id === 3 ? '/assets/images/high_flag.svg' : ticket.ticket_priority.priority_details.id === 2 ? '/assets/images/medium_flag.svg' : '/assets/images/low_flag.svg'" alt="">
                                    <span :class="`text ${ticket.ticket_priority.priority_details.name.toLowerCase()}`">{{ ticket.ticket_priority.priority_details.name }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="assignee" @click="assignTo = ticket.id">
                            <div v-if="ticket.assigned_users.length" class="assigned">
                                <img v-if="ticket.assigned_users.length && ticket.assigned_users[0].avatar" :src="imgURL + ticket.assigned_users[0].avatar" :title="ticket.assigned_users[0].name">
                                <span v-else class="noimg">{{ticket.assigned_users[0].name.charAt(0)}}</span>
                            </div>
                            <div v-else>
                                not assigned
                            </div>
                        </div>
                    </div>
                </div>
                <div class="buttons">
                    <span @click="$emit('close', 1)">Yes</span>
                    <span @click="$emit('close', 0)">No</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        ticket: {
            required: true,
            type: Object,
            default: () => {}
        },
    },
}
</script>

<style lang="scss" scoped>
.delete-ticket {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.2);
    top: 0;
    left: 0;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    .popup{
        border-radius: 5px;
        background: white;
        width: 400px;
        box-shadow: 0px 1px 6px #CAD6E280;
        border-bottom: solid 1px #F2F5F8;
        .title {
            box-shadow: 0px 1px 6px #CAD6E280;
            border-bottom: solid 1px #F2F5F8;
            padding: 20px;
            text-transform: uppercase;
            color: #ED0423;
            font-weight: bold;
            font-size: 1.2rem;
        }
        .message {
            padding: 20px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            .warning {
                font-size: 1.4rem;
                font-weight: bold;
            }
            .ticket {
                width: 100%;
                display: flex;
                cursor: pointer;
                user-select: none;
                min-height: 100px;
                &.drag {
                    opacity: 0.5;
                }

                .color{
                    width: 2%;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    &.high {
                        background: #ED0423;
                    }
                    &.medium {
                        background: #FB9A4B;
                        }
                    &.low {
                        background: #CAD6E2;
                    }
                }
                .text{
                    &.high {
                        color: #ED0423;
                    }
                    &.medium {
                        color: #FB9A4B;
                        }
                    &.low {
                        color: #CAD6E2;
                    }
                }
                .info{
                    width: 98%;
                    padding: 10px;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                    box-shadow: 0px 1px 6px #CAD6E280;
                    position: relative;
                    .name {
                        font-weight: bold;
                    }
                    .serial {
                        font-weight: 500;
                        font-size: 0.8rem;
                    }
                    .client {
                        color: #CAD6E2;
                        font-size: 0.8rem;
                    }
                    .assignee {
                        position: absolute;
                        bottom: 10px;
                        right: 10px;
                        display: flex;
                        gap: 5px;
                        .assigned{
                            width: 20px;
                            height: 20px;
                            border-radius: 50%;
                            overflow: hidden;
                            img{
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        .arrow {
                            width: 10px;
                            transform: rotate(270deg);
                        }
                        .noimg{
                            width: 100%;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-weight: bold;
                            text-transform: uppercase;
                            background: var(--primary-color);
                            color: white;
                        }
                    }

                    .priority {
                        margin-top: 10px;
                        position: relative;
                        .current {
                            display: flex;
                            gap: 20px;
                            .prio {
                                display: flex;
                                gap: 10px;
                            }
                        }
                    }
                }
            }
            .buttons{
                display: flex;
                justify-content: center;
                gap: 10px;
                span{
                    background: #F2F5F8;
                    box-shadow: 0px 1px 3px #00000033;
                    text-align: center;
                    padding: 10px 50px;
                    color: var(--primary-color);
                    user-select: none;
                    cursor: pointer;
                    border-radius: 30px;
                    transition: 0.2s;

                    &:hover {
                        background: #dee0e2;
                    }
                }
            }
        }
    }
}
</style>