<template>
<div class="overview-container">
    <section class="columns">
		<template v-if="loaded">
			<template v-for="(status, i) in entries" :key="i">
				<Columns :allStatus="entries" :filter="filter" :users="users" :status="status" :color="colors[i]" :splice="dragFrom && status.id === dragFrom ? newItem : {}" :newItem="dragTo && status.id === dragTo ? newItem : {}" @startDrag="handleStartDrag" @handleDrag="handleDrag" @stopDrag="handleStopDrag" />
			</template>
		</template>
		<template v-else>
			<div class="null">
				<div class="loading">
					<span class="loader"></span>
				</div>
			</div>
		</template>
  </section>
</div>
  
  <assign
    v-if="assignPopup.length"
    :users="users"
    :ticket="assignPopup[0]"
    @close="handleAssign"
  />
  <unassign
    v-if="unassignPopup.length"
    :users="users"
    :ticket="unassignPopup[0]"
    @close="handleUnassign"
  />
    <CloseForm
        v-if="closeTicket.length"
        :title="'Close Ticket'"
        :items="toClose(closeTicket[0])"
        :response="response"
        @data="handleCloseForm"
        @close="closeTicket = []"
    />
</template>

<script>
import axiosInstance from '@/services/AxiosTokenInstance'
import Columns from "./Columns/Index.vue";
import Assign from "./Assign.vue";
import Unassign from "./Unassign.vue";
import CloseForm from '@/components/Create/Index.vue'
import api from "@/mixins/optionsApi";

export default {
	props: {
		users: {
			type: Array,
			required: false,
			default: () => []
		},
		filter: {
			type: Array,
			required: false,
			default: () => []
		}
	},

    mixins: [api],

	data() {
		return {
			entries: [],
			loaded: false,
			colors: ["#FB4B5A", "#FB9A4B", "#FFEB99", "#06D6A0", "rgb(6 96 73)"],
			dragged: {},
			dragTo: 0,
			dragFrom: 0,
			newItem: {},
			assignPopup: [],
			unassignPopup: [],
			ticketEntries: [],
            closeTicket: [],
            response: [],
			total: 0
		}
	},

	components: {
		Columns,
		Assign,
		Unassign,
        CloseForm
	},

	methods: {
        toClose(item) {
            const warranty_end = item.product.warranty_end_date
            const warranty_message = warranty_end ? `This Product is in warranty until ${warranty_end}` : 'This Product has no Warranty defined'
            return [
                {
                    type: 'select',
                    name: 'assistance_type',
                    label: 'Assistance Type',
                    required: true,
                    options: this.api('assistanceTypes')
                },
                {
                    template: 'multiple-select-related',
                    name: 'type',
                    label: 'Problem Type',
                    required: true,
                    options: this.api('parent-types'),
                    relate: {
                        template: 'select',
                        name: 'type_child',
                        required: true,
                        label: 'Problem Subtype',
                        options: 'child-types'
                    }
                },
                {
                    type: "boolean-message",
                    name: "charge",
                    label: "Coverage?",
                    message: warranty_message,
                    required: true,
                },
                {
                    type: "file",
                    name: "documents",
                    label: "Upload Document",
                    required: false,
                },
                {
                    type: "textarea",
                    name: "notes",
                    label: "Notes",
                    required: true,
                },
            ];
        },
		async getStatusList() {
			const response = await axiosInstance.get('ticket-status-list').finally(() => this.loaded = true)
			if (response.status === 200) this.entries = response.data.data.filter(el => el.id !== 6)
		},

		handleStartDrag(data) {
			this.dragged = data[0]
			this.dragFrom = data[1]
		},

		handleDrag(data) {
			this.dragTo = data
		},

		async handleStopDrag() {
			if (this.dragFrom === 1 && this.dragFrom !== this.dragTo) {
				this.assignPopup.push(this.dragged)
				return
			}
			else if (this.dragTo === 1 && this.dragFrom !== this.dragTo) {
				this.unassignPopup.push(this.dragged)
				return
			}
			else if (this.dragTo === 5 && this.dragFrom !== this.dragTo) {
				this.closeTicket.push(this.dragged)
				return
			}
			else this.drag()

			const toSend = {
				ticket_id: this.newItem.id,
				status_id: this.dragTo
			}

			await axiosInstance.post('change-ticket-status', toSend)
		},

		handleAssign(data) {
			if (data) {
				const id = this.assignPopup[0].id;
				data.forEach((element) => {
					this.handleAssignee(element, id);
				});

				this.emitter.emit('NewAssignees', [id, data])

				this.drag()
				
				this.assignPopup = [];
			} else this.assignPopup = [];
		},

		async handleUnassign(data) {
			if (data) {
				let orgData = JSON.parse(localStorage.getItem("organization"));
				const id = this.unassignPopup[0].id;
				const toSend = {
					org_id: orgData.id,
					ticket_id: id,
					user_ids: this.unassignPopup[0].assigned_users.map((el) => el.id),
				};
				await axiosInstance.post(`un-assign-ticket-from-all-users`, toSend);

				this.emitter.emit('removeAssignees', id)
				
				this.drag()

				this.unassignPopup = [];
			} else this.unassignPopup = [];
		},

        async handleCloseForm(data) {
            let orgData = JSON.parse(localStorage.getItem("organization"));
            const id = this.closeTicket[0].id;

            const formData = new FormData();

            if (data.documents && data.documents.length) {
                for (let i = 0; i < data.documents.length; i++) {
                    formData.append(`form_documents[${i}]`, data.documents[i].og_doc);
                }
            }
            
            if (this.closeTicket[0].assigned_users && this.closeTicket[0].assigned_users.length) {
                for (let i = 0; i < this.closeTicket[0].assigned_users.length; i++) {
                    formData.append(`user_ids[${i}]`, this.closeTicket[0].assigned_users[i].id);
                }
            }

            formData.append(`org_id`, orgData.id);
            formData.append(`ticket_id`, id);
            formData.append(`status_id`, 5);
            formData.append(`chargeable`, data.charge);

            if (data.assistance_type) formData.append(`assistance_type`, data.assistance_type.id);

            if(data.type) formData.append(`typology_id`, data.type_child?.id || data.type.id);

            const response = await axiosInstance.post(`close-ticket`, formData);

            if(response) {  
                this.emitter.emit('closeTicket', id)
            
                this.drag()

                this.closeTicket = [];
            }
        },

		drag() {
			if (this.dragFrom !== this.dragTo) this.newItem = this.dragged

			this.dragged = {}
			setTimeout(() => {
				this.dragFrom = 0
				this.dragTo = 0
				this.newItem = {}
			}, 50);
		},

		async handleAssignee(assignee, id) {
			let orgDataString = localStorage.getItem("organization");
			if (orgDataString) {
				let orgData = JSON.parse(orgDataString);
				let params = {
					id_ticket: id,
					id_organization: orgData.id,
					users: [{ id_user: assignee.id }],
				};
				
				await axiosInstance.post('user-assign-ticket', params)
			}
		},
	},

	mounted() {
		this.getStatusList()

		this.emitter.on('newAssignTicket', (data) => {
			this.dragged = data
			this.dragFrom = 1
			this.dragTo = 2
			this.drag()
		})
	}
};
</script>

<style lang="scss" scoped>
.overview-container {
    width: 100%;
    overflow: auto;
    transition: 0.2s;
}
.columns {
	display: grid;
	grid-template-columns: 22rem 22rem 22rem 22rem 22rem;
    gap: 1rem;
	width: 100%;
	height: 65vh;
    overflow: auto;
    padding-bottom: 5px;

    @media only screen and (max-width: 768px) {
        grid-template-columns: 95% 95% 95% 95% 95%;
    }

	.null {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .loading {
            height: unset;
            background: none;
            .loader {
                border: solid 8px $grey;
                border-top: 8px solid var(--primary-color);
            }
        }
    }
}



</style>