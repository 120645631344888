<template>
    <div class="assign" v-click-outside="handleOutside">
        <div v-if="currentUsers.length" class="assigned" @click="select = !select">
            <template v-for="(user,i) in currentUsers" :key="i">
                <template v-if="i < 4">
                    <figure :title="user.name">
                        <img v-if="user.avatar" :src="imgURL + user.avatar" alt="avatar">
                        <span v-else> {{user.name.charAt(0)}} </span>
                    </figure>
                </template>
            </template>
        </div>
        <span v-else class="not-assigned" @click="select = !select">Not Assigned</span>
        <figure class="arrow" :class="{opened: select}" @click="select = !select">
            <img src="/assets/images/left-arrow.svg" alt="arrow">
        </figure>
        <transition name="open">
            <div class="options" v-if="select">
                <template v-for="(user, n) in avUsers" :key="n">
                    <div class="item" @click="handleNewUser(user)">
                        <figure class="icon">
                            <img v-if="user.image" :src="user.image" alt="avatar">
                            <span v-else> {{user.name.charAt(0)}} </span>
                        </figure>
                        <span class="name" :class="[user.name.toLowerCase()]">{{user.name}}</span>
                    </div>
                </template>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            required: true
        },
        users: {
			type: Array,
			required: false,
			default: () => []
		},
        column: {
            type: Number,
            required: true
        }
    },

    data() {
        return {
            imgURL: process.env.VUE_APP_DO_SPACES,
            select: false,
            currentUsers: this.value.assigned_users
        }
    },

    watch: {
        value() {
            this.currentUsers = this.value.assigned_users
            this.select = false
        }
    },

    computed: {
        avUsers() {
            return this.users.filter(el => {
                return !this.currentUsers.find(element => {
                    return element.id === el.id;
                });
            })
        }
    },

    methods: {
        handleOutside() {
            this.select = false
        },

        handleNewUser(data) {
            const newUser = {
                id: data.id,
                avatar: data.image ? data.image.replace(this.imgURL, '') : undefined,
                name: data.name
            }

            this.currentUsers.push(newUser)

            if (this.column === 1) this.emitter.emit('newAssignTicket', this.value)

            this.$emit('newUser', newUser)
        }
    },

    mounted() {
        this.emitter.on("NewAssignees", (data) => {
            if(data[0] === this.value.id) {
                data[1].forEach(element => {
                    const newUser = {
                        id: element.id,
                        avatar: element.image ? element.image.replace(this.imgURL, '') : undefined,
                        name: element.name
                    }
                    this.currentUsers.push(newUser)
                });
            }
        })
        this.emitter.on("removeAssignees", (data) => {
            setTimeout(() => {
                if(data === this.value.id) {
                    this.currentUsers = []
                }
            }, 50);
            
        })
    }
}
</script>

<style lang="scss" scoped>
.open-enter-active {
  animation: open 0.2s;
}
.open-leave-active {
  animation: open 0.2s reverse;
}

    .assign {
        display: flex;
        gap: 0.25rem;
        align-items: center;
        position: relative;
        .not-assigned {
            font-size: 0.8rem;
        }
        .assigned {
            display: flex;
            gap: -0.5rem;

            figure {
                height: 20px;
                width: 20px;
                border-radius: 50%;
                overflow: hidden;
                margin-left: -10px;

                &:first-of-type {
                    margin-left: 0px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                span {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: var(--primary-color);
                    color: white;
                    font-weight: 600;
                    font-size: 0.8rem;
                }
            }
        }

        .arrow {
            width: 10px;
            height: 10px;
            display: flex;
            align-items: center;
            opacity: 0.2;

            img {
                transition: 0.2s;
                transform: rotate(270deg);
            }

            &.opened {
                img {
                    transform: rotate(90deg);
                }
            }
        }

        .options {
            background: white;
            width: calc(100% + 10px);
            position: absolute;
            top: calc(100% + 5px);
            right: -5px;
            z-index: 1;
            border-bottom-left-radius: 2px;
            border-bottom-right-radius: 2px;
            box-shadow: 0 1px 6px rgba(202, 214, 226, 0.5);
            max-height: 200px;
            overflow: auto;

            .item {
                display: flex;
                gap: 0.5rem;
                padding: 5px;
                border-bottom: solid 1px $grey;
                transition: 0.2s;

                &:hover {
                    background: #f5f5f5;
                }

                &:last-of-type {
                    border: none;
                }

                figure {
                    height: 20px;
                    width: 20px;
                    border-radius: 50%;
                    overflow: hidden;
                    margin-left: -10px;

                    &:first-of-type {
                        margin-left: 0px;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                    span {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: var(--primary-color);
                        color: white;
                        font-weight: 600;
                        font-size: 0.8rem;
                    }
                }

                .name {
                    width: 80%;
                    white-space: nowrap;
                    display: block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    
</style>