<template>
    <div class="prios" v-click-outside="handleOutside">
        <div class="selected" @click="select = !select">
            <figure class="icon">
                <Flag :color="priorities.filter(el => el.id === currentPrio.id)[0].color" />
            </figure>
            <span class="name" :class="[currentPrio.name.toLowerCase()]">{{currentPrio.name}}</span>
            <figure class="arrow" :class="{opened: select}">
                <img src="/assets/images/left-arrow.svg" alt="arrow">
            </figure>
        </div>
        <transition name="open">
            <div class="options" v-if="select">
                <template v-for="(prio, n) in priorities.filter(el => el.id !== currentPrio.id)" :key="n">
                    <div class="item" @click="handleNewPrio(prio)">
                        <figure class="icon">
                            <Flag :color="prio.color"/>
                        </figure>
                        <span class="name" :class="[prio.name.toLowerCase()]">{{prio.name}}</span>
                    </div>
                </template>
            </div>
        </transition>
        
    </div>
</template>

<script>
import Flag from '@/components/Ui/Ticket/Flag.vue'

export default {
    props: {
        value: {
            type: Object,
            required: true
        }
    },

    components: {
        Flag
    },

    data() {
        return {
            select: false,
            currentPrio: this.value
        }
    },

    computed: {
        priorities() {
            return [
                {
                    id: 1,
                    color: "#cad6e2",
                    name: "low",
                },
                {
                    id: 2,
                    color: "#fb9a4b",
                    name: "medium",
                },
                {
                    id: 3,
                    color: "#ed0423",
                    name: "high",
                },
            ]
        } 
    },

    watch: {
        value() {
            this.currentPrio = this.value
        }
    },

    methods: {
        handleOutside() {
            this.select = false
        },

        handleNewPrio(prio) {
            this.currentPrio = prio
            this.select = false

            this.$emit('newPrio', prio)
        }
    },
}
</script>

<style lang="scss" scoped>
.open-enter-active {
  animation: open 0.2s;
}
.open-leave-active {
  animation: open 0.2s reverse;
}

    .prios {
        position: relative;
        .selected {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            .icon {
                height: 15px;
                svg {
                    height: 100%;
                }
            }

            .arrow {
                width: 10px;
                height: 10px;
                display: flex;
                align-items: center;
                opacity: 0.2;
                position: absolute;
                right: 0;

                img {
                    transition: 0.2s;
                    transform: rotate(270deg);
                }

                

                &.opened {
                    img {
                        transform: rotate(90deg);
                    }
                }
            }
        }

        .options {
            background: white;
            width: calc(100% + 10px);
            position: absolute;
            top: calc(100% + 5px);
            left: -5px;
            z-index: 1;
            border-bottom-left-radius: 2px;
            border-bottom-right-radius: 2px;
            box-shadow: 0 1px 6px rgba(202, 214, 226, 0.5);
            max-height: 200px;
            overflow: auto;

            .item {
                display: flex;
                gap: 0.5rem;
                padding: 5px;
                border-bottom: solid 1px $grey;
                transition: 0.2s;

                &:hover {
                    background: #f5f5f5;
                }

                &:last-of-type {
                    border: none;
                }
            }
        }
    }

    

    .name {
        text-transform: capitalize;
        &.high {
            color: #ed0423;
        }
        &.medium {
            color: #fb9a4b;
        }
        &.low {
            color: #cad6e2;
        }
    }
</style>