<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="11.972" height="15.2" viewBox="0 0 11.972 15.2">
        <g transform="translate(-678.585 -499.348)">
            <path class="a" d="M689.956,506.93H679.185v-6.983h10.772l-2.372,3.688Z" transform="translate(0 0)" :stroke="color" :fill="color"/>
            <line class="b" y2="12.177" transform="translate(679.185 501.771)" :stroke="color"/>
        </g>
    </svg>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            required: false,
            default: () => '#cad6e2'
        }
    },
}
</script>